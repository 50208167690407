import { ServiceNames } from '@nvs/gatsby-theme-leap-core/src/components/hoc/constants/service.constants';
import { EnrollmentContextService } from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context';
import { consentsMapper as themeConsentsMapper } from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/mappers/consents';
import { ContextService } from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context';

export const consentsMapper = (
	context: ContextService,
	enrollmentContext: EnrollmentContextService
): { name: string; dateTime: string }[] => {
	const filteredServices = enrollmentContext.eligibleServices(context).filter(
		(service) => !enrollmentContext.input.discardedServices.includes(service.id)
	);
	const serviceConsents = filteredServices.reduce(
		(services: string[], service) => {
			services.push(...service.consents.service);
			return services;
		},
		[]
	);
	const consents = serviceConsents.concat(enrollmentContext.input.consents);
	if (!consents.find((c) => c === ServiceNames.adherence)) {
		enrollmentContext.input.consents.push(ServiceNames.adherence);
	}
	return themeConsentsMapper(context, enrollmentContext);
};