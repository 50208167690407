import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Menu from "../common/menu";
import BrandLogo from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/logo/logo";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import SocialNetworks from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/social/social-networks";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import BrandHeaderMobile from "./brand-header-mobile";
import { Link } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/link";
import RichText from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/rich-text";
import { enrollmentContext } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";

type hoverMenuType = {
  id: number;
  text1: string;
  text2: string;
  title: string;
  link: string;
};

type headerMenuType = {
  id: number;
  title1: string;
  title2: string;
  headerHoverMenu: hoverMenuType[];
};
const BrandHeader: React.FC = () => {
  const ctxt = useContext(context);
  const enrollmentCtxt = useContext(enrollmentContext);
  const { site } = useStaticQuery(query);

  const brandHeaderConfig = {
    headerMiddleMenu: [
      {
        id: 1,
        title1: "About",
        title2: "Your Heart",
        headerHoverMenu: [
          {
            id: 1,
            text1: "What Is Heart",
            text2: "Failure?",
            title: "What Is Heart",
            link: "https://www.entresto.com/what-is-hf",
          },
          {
            id: 2,
            text1: "Risks &",
            text2: "Hospitalization",
            title: "Risks & Hospitalization",
            link: "https://www.entresto.com/managing-hf",
          },
        ],
      },
      {
        id: 2,
        title1: "About",
        title2: "ENTRESTO",
        headerHoverMenu: [
          {
            id: 1,
            text1: "What Sets",
            text2: "ENTRESTO Apart?",
            title: "What Sets",
            link: "https://www.entresto.com/how-entresto-works",
          },
          {
            id: 2,
            text1: "Talking to",
            text2: "Your Doctor",
            title: "Talking to",
            link: "https://www.entresto.com/doctor-discussion-guide",
          },
          {
            id: 3,
            text1: "Starting",
            text2: "ENTRESTO",
            title: "Starting",
            link: "https://www.entresto.com/starting-entresto",
          },
          {
            id: 4,
            text1: "Patient",
            text2: "Stories",
            title: "Patient",
            link: "https://www.entresto.com/patient-stories",
          },
        ],
      },
      {
        id: 3,
        title1: "Living With",
        title2: "Heart Failure",
        headerHoverMenu: [
          {
            id: 1,
            text1: "Patient",
            text2: "Resources",
            title: "Patient",
            link: "https://www.entresto.com/patient-support",
          },
          {
            id: 2,
            text1: "Stay",
            text2: "Active",
            title: "Stay",
            link: "https://www.entresto.com/active-lifestyle",
          },
          {
            id: 3,
            text1: "Managing",
            text2: "Medicine",
            title: "Managing",
            link: "https://www.entresto.com/managing-medication",
          },
          {
            id: 4,
            text1: "Track Your",
            text2: "Symptoms",
            title: "Track",
            link: "https://www.entresto.com/tracking-symptoms",
          },
          {
            id: 5,
            text1: "For",
            text2: "Caregivers",
            title: "For Caregivers",
            link: "https://www.entresto.com/support-for-caregivers",
          },
          {
            id: 6,
            text1: "Recipes",
            text2: "",
            title: "Recipes",
            link: "https://www.entresto.com/heart-healthy-recipes",
          },
        ],
      },
      {
        id: 4,
        title1: "Savings",
        title2: "and Support",
        headerHoverMenu: [
          {
            id: 1,
            text1: "How to",
            text2: "Save",
            title: "How to",
            link: "https://www.entresto.com/financial-support",
          },
          {
            id: 2,
            text1: "ENSPIRE Program",
            text2: "from ENTRESTO<sup>®</sup>",
            title: "ENSPIRE Program",
            link: "https://www.entresto.com/enspire-program-from-entresto",
          },
          {
            id: 3,
            text1: "Sign up for Savings",
            text2: "and Support",
            title: "Sign up for Savings",
            link: "/",
          },
        ],
      },
    ],
  };

  if (enrollmentCtxt.isFastTrack(ctxt)) {
    brandHeaderConfig?.headerMiddleMenu[1]?.headerHoverMenu.push({
      id: 5,
      text1: "Sign Up to",
      text2: "Learn More",
      title: "Register to",
      link: "https://www.entresto.com/registration",
    });
  }

  if (site.siteMetadata.experience === "support") {
    brandHeaderConfig.headerMiddleMenu[3].headerHoverMenu.splice(1, 1);
  }

  return (
    <>
      <BrandHeaderMobile />
      <header className="hidden [@media(min-width:1050px)]:block print:hidden">
        <div className="grid grid-cols-3 [@media(max-width:1500px)]:grid-cols-4">
          <div className="flex flex-col justify-center items-end bg-white pr-5">
            <Link
              href="https://www.entresto.com"
              rel="noopener noreferrer"
              target="_blank"
              title="Entresto Logo"
            >
              <BrandLogo className="w-full lg:[max-width:187px] [min-width:125px] [max-width:125px]" />
            </Link>
          </div>
          <div className="col-span-2 [@media(max-width:1500px)]:col-span-3 flex justify-start bg-gradient-to-l from-[#EAB308] to-[#FDE047] print:hidden">
            <div className="flex items-center py-6">
              <nav className="print:hidden">
                <div className="px-8 text-xs text-font">
                  <Menu />
                </div>
                <div className="flex justify-center px-5 pt-6">
                  <ul className="flex align-center text-brand">
                    {brandHeaderConfig?.headerMiddleMenu?.map(
                      (headerMiddleMenuItem: headerMenuType) => {
                        return (
                          <>
                            <li
                              className="text-l font-bold group cursor-pointer"
                              key={headerMiddleMenuItem.id}
                            >
                              <div className="flex flex-col text-center">
                                <span className="text-xl leading-6">
                                  {headerMiddleMenuItem.title1}
                                </span>
                                <span className="text-xl leading-6">
                                  {headerMiddleMenuItem.title2}
                                </span>
                              </div>
                              <div className="absolute hidden group-hover:flex">
                                <div className="relative mt-4 right-12 pt-1">
                                  <div className="relative left-8">
                                    <div className="absolute left-8 bottom-0 pl-2 pb-1">
                                      <ChevronDownIcon
                                        className="h-5"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>

                                  <ul className="flex text-white px-4 py-3 text-sm bg-[#999999] border-t-4 border-[#333333] shadow-leap z-10 relative">
                                    {headerMiddleMenuItem.headerHoverMenu?.map(
                                      (headerHoverItem: hoverMenuType) => (
                                        <>
                                          <li
                                            className="flex items-center px-5 cursor-pointer hover:text-font"
                                            key={headerHoverItem.id}
                                          >
                                            <Link
                                              className="no-underline"
                                              href={headerHoverItem.link}
                                              title={`${headerHoverItem.text1} ${headerHoverItem.text2}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <div className="flex flex-col text-center">
                                                <span>
                                                  {headerHoverItem.text1}
                                                </span>
                                                <span>
                                                  {
                                                    <RichText
                                                      html={
                                                        headerHoverItem.text2
                                                      }
                                                    />
                                                  }
                                                </span>
                                              </div>
                                            </Link>
                                          </li>
                                          <span className="border-r my-2 last:hidden"></span>
                                        </>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="px-6 font-extralight text-3xl last:hidden">
                              |
                            </li>
                          </>
                        );
                      }
                    )}
                  </ul>
                  <div className="flex pl-8">
                    <SocialNetworks items={ctxt.json.socialNetworks} />
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default BrandHeader;

const query = graphql`
  query experienceQuery {
    site {
      siteMetadata {
        experience
      }
    }
  }
`;
