import React, { useContext, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import SocialNetworks from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/social/social-networks";
import BrandLogo from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/logo/logo";
import context from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { RichText } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components";
import { useScrollBlock } from "./scroll";
import { Link } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/link";
import { enrollmentContext } from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";

const BrandHeaderMobile: React.FC = () => {
  const ctxt = useContext(context);
  const enrollmentCtxt = useContext(enrollmentContext);
  const isFastTrack = enrollmentCtxt.isFastTrack(ctxt);
  const { site } = useStaticQuery(query);
  const [isShown, setIsShown] = useState(false);
  const [disclosureId, setDisclosureId] = useState(0);
  const [openDisclosure, setOpenDisclosure] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleClick = () => {
    if (!isShown) {
      blockScroll();
    } else {
      allowScroll();
    }
    setIsShown(!isShown);
  };

  return (
    <header className="block [@media(min-width:1050px)]:hidden">
      <div className="bg-gradient-to-r from-[#FDE047] to-[#EAB308] flex flex-1 flex-row justify-between py-3 text-font font-bold [@media(min-width:1050px)]:hidden px-10 py-3">
        <div
          onClick={handleClick}
          className="flex flex flex-col justify-center items-center gap-1"
        >
          <div className="flex flex-col justify-center items-center bg-gradient-to-b from-brand-secondary to-brand rounded-full w-11 h-11">
            <div className="border-y-2 text-white h-2.5 w-6"></div>
          </div>
          <div className="text-brand text-sm">
            {ctxt.json.pages.header.menu.menu_landing_mobile}
          </div>
        </div>
        <div className="flex">
          <SocialNetworks items={ctxt.json.socialNetworks} />
        </div>
      </div>
      <div className="flex justify-center bg-white py-4">
        <Link
          href="https://www.entresto.com"
          rel="noopener noreferrer"
          target="_blank"
          title="Entresto Logo"
        >
          <BrandLogo className="w-52" />
        </Link>
      </div>
      <div
        id="mobile-open"
        style={{ display: isShown ? "block" : "none" }}
        className="w-full h-screen absolute top-0 z-[100] bg-font bg-opacity-50"
      >
        <div className="w-[90%] h-full bg-gradient-to-b from-white via-white to-transparent relative">
          <div className="absolute right-4 w-32 mt-5 z-20">
            <Link
              href="https://www.entresto.com"
              rel="noopener noreferrer"
              target="_blank"
              title="Entresto Logo"
            >
              <BrandLogo />
            </Link>
          </div>
          <div className="w-full h-full overflow-y-scroll bg-gradient-to-b from-brand-secondary to-brand rounded-r-[2500px] rounded-br-[2500px] relative z-10">
            <div className="absolute left-5 top-7 text-white w-9 h-9">
              <XMarkIcon onClick={handleClick} />
            </div>
            <div className="flex flex-col items-start text-white divide-y-2 gap-5 pt-24 pb-12">
              <div className="flex flex-col gap-4 font-bold px-7">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-4">
                    <ul
                      onClick={() => setDisclosureId(1)}
                      className="w-52 flex justify-between items-center text-xl text-left leading-6"
                    >
                      <li className="w-32">
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .about_heart_landing_mobile
                          }
                        />
                      </li>
                      <span
                        className={
                          openDisclosure === true && disclosureId === 1
                            ? "text-4xl font-normal text-cta-hover"
                            : ""
                        }
                        onClick={() => setOpenDisclosure(!openDisclosure)}
                      >
                        {openDisclosure === true && disclosureId === 1
                          ? "-"
                          : "+"}
                      </span>
                    </ul>
                    <ul
                      className={
                        openDisclosure === true && disclosureId === 1
                          ? "block w-60 flex flex-col gap-4"
                          : "hidden"
                      }
                    >
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .what_is_heart_failure_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu.risks_landing_mobile
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4">
                    <ul
                      onClick={() => setDisclosureId(2)}
                      className="w-52 flex justify-between items-center text-xl text-left leading-6"
                    >
                      <li className="w-32">
                        <RichText
                          html={
                            ctxt.json.pages.header.menu.about_ent_landing_mobile
                          }
                        />
                      </li>
                      <span
                        className={
                          openDisclosure === true && disclosureId === 2
                            ? "text-4xl font-normal text-cta-hover"
                            : ""
                        }
                        onClick={() => setOpenDisclosure(!openDisclosure)}
                      >
                        {openDisclosure === true && disclosureId === 2
                          ? "-"
                          : "+"}
                      </span>
                    </ul>
                    <ul
                      className={
                        openDisclosure === true && disclosureId === 2
                          ? "block w-60 flex flex-col gap-4"
                          : "hidden"
                      }
                    >
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .what_entresto_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .talking_doctor_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .starting_entresto_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .patient_stories_landing_mobile
                          }
                        />
                      </li>
                      {isFastTrack ? (
                        <li>
                          <RichText
                            html={
                              ctxt.json.pages.header.menu
                                .register_landing_mobile
                            }
                          />
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4">
                    <ul
                      onClick={() => setDisclosureId(3)}
                      className="w-52 flex justify-between items-center text-xl text-left leading-6"
                    >
                      <li className="w-32">
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .living_with_landing_mobile
                          }
                        />
                      </li>
                      <span
                        className={
                          openDisclosure === true && disclosureId === 3
                            ? "text-4xl font-normal text-cta-hover"
                            : ""
                        }
                        onClick={() => setOpenDisclosure(!openDisclosure)}
                      >
                        {openDisclosure === true && disclosureId === 3
                          ? "-"
                          : "+"}
                      </span>
                    </ul>
                    <ul
                      className={
                        openDisclosure === true && disclosureId === 3
                          ? "block w-60 flex flex-col gap-4"
                          : "hidden"
                      }
                    >
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .patient_resources_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .stay_active_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .managing_meds_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .track_symptoms_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .caregivers_landing_mobile
                          }
                        />
                      </li>
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu.recipes_landing_mobile
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col gap-4">
                    <ul
                      onClick={() => setDisclosureId(4)}
                      className="w-52 flex justify-between items-center text-xl text-left leading-6"
                    >
                      <li className="w-32">
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .savings_support_landing_mobile
                          }
                        />
                      </li>
                      <span
                        className={
                          openDisclosure === true && disclosureId === 4
                            ? "text-4xl font-normal text-cta-hover"
                            : ""
                        }
                        onClick={() => setOpenDisclosure(!openDisclosure)}
                      >
                        {openDisclosure === true && disclosureId === 4
                          ? "-"
                          : "+"}
                      </span>
                    </ul>
                    <ul
                      className={
                        openDisclosure === true && disclosureId === 4
                          ? "block w-60 flex flex-col gap-4"
                          : "hidden"
                      }
                    >
                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu.howtosave_landing_mobile
                          }
                        />
                      </li>
                      {site.siteMetadata.experience !== "support" && (
                        <li>
                          <RichText
                            html={
                              ctxt.json.pages.header.menu.enspire_landing_mobile
                            }
                          />
                        </li>
                      )}

                      <li>
                        <RichText
                          html={
                            ctxt.json.pages.header.menu
                              .savings_support_registration_mobile
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flex flex-col pt-5 w-52 font-bold px-7">
                <div className="flex flex-col gap-4 w-52">
                  <RichText
                    html={
                      ctxt.json.pages.header.menu.prescribing_landing_mobile
                    }
                  />
                  <RichText
                    html={
                      ctxt.json.pages.header.menu
                        .prescribing_patient_landing_mobile
                    }
                  />
                  <RichText
                    html={
                      ctxt.json.pages.header.menu
                        .for_us_residents_landing_mobile
                    }
                  />
                  <RichText
                    html={
                      ctxt.json.pages.header.menu
                        .for_non_us_residents_landing_mobile
                    }
                  />
                  <RichText
                    html={ctxt.json.pages.header.menu.healthcare_landing_mobile}
                  />
                  <RichText
                    html={
                      ctxt.json.pages.header.menu
                        .spanish_language_landing_mobile
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default BrandHeaderMobile;

const query = graphql`
  query experienceQuery {
    site {
      siteMetadata {
        experience
      }
    }
  }
`;
