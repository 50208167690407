import { NavigationItemProps } from "@nvs/gatsby-theme-leap-core/src/components/hoc/components/header/navigation/item";
import {
  AnswerValueProp,
  InputQuestion,
} from "@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context";

export type QuestionChoice = {
  value: string | boolean | number;
  label?: string;
  feedback?: string;
  continue?: boolean;
  description?: ChoiceDescription;
};

export type ChoiceDescription = {
  header?: string;
  body?: string;
};

interface QuestionParent {
  id: string;
  value: boolean | string;
  operator?: string;
}

export interface QuestionConditions {
  parents: QuestionParent[];
  discardedServices?: string[];
}

export class Question {
  id: string;
  label?: string;
  title?: string;
  type: string;
  performer?: string;
  description: string;
  choices?: QuestionChoice[];
  help?: string[];
  buttonLabel?: string;
  mlrVersion?: string;
  header?: string;
  conditions?: QuestionConditions;

  constructor(rawQuestion: Question) {
    this.id = rawQuestion.id;
    this.title = rawQuestion.title;
    this.label = rawQuestion.label;
    this.type = rawQuestion.type;
    this.performer = rawQuestion.performer;
    this.description = rawQuestion.description;
    this.choices = rawQuestion.choices;
    this.buttonLabel = rawQuestion.buttonLabel;
    this.mlrVersion = rawQuestion.mlrVersion;
    this.header = rawQuestion.header;
    this.conditions = rawQuestion.conditions;
    if (this.type === "boolean") {
      if (!this.choices) {
        this.choices = [{ value: true }, { value: false }];
      }
      if (this.choices.length === 1) {
        if (this.choices.find((choice) => choice.value === true)) {
          this.choices.push({ value: false });
        } else {
          this.choices.push({ value: true });
        }
      }
    }
    this.help = rawQuestion.help;
  }

  checkConditions(
    discardedServices: string[] = [],
    previousAnswers: { id: string; answer: Date | AnswerValueProp }[] = [],
    conditions?: QuestionConditions
  ): boolean {
    if (!conditions) {
      return true;
    }
    const discarded = conditions.discardedServices;
    const ln = discarded?.length || 0;
    if (
      discarded &&
      ln &&
      (ln !== discardedServices.length ||
        discarded.every((service) => !discardedServices.includes(service)))
    ) {
      return false;
    }
    if (
      !conditions.parents ||
      !conditions.parents.length ||
      !previousAnswers.length
    ) {
      return true;
    }
    return conditions.parents.every((condition) => {
      const previousAnswer = previousAnswers.find(
        (answer) => answer.id === condition.id
      );
      if (previousAnswer) {
        return condition.operator === "not"
          ? condition.value !== previousAnswer.answer
          : condition.value === previousAnswer.answer;
      }
      return false;
    });
  }
}

const loadQuestions = (questions: {
  [key: string]: Question[];
}): { [key: string]: Question[] } => {
  for (const key of Object.keys(questions)) {
    questions[key] = questions[key].map((question) => new Question(question));
  }
  return questions;
};

export type ProgramSelection = {
  title: string;
  description: string;
};

export type Field = {
  name: string;
  service?: string;
  label: string;
  question?: string;
  help?: string;
  options?: { key: string; value: string }[];
  errors?: { [key: string]: string };
};

export type SocialNetwork = {
  name: string;
  link: string;
  type?: string;
};

export type footerSection = {
  className?: string;
};

export type footerItemContent = {
  id: number;
  label: string;
  url: string;
  type: string;
  ariaText: string;
};

export type footerColumnHeader = {
  className?: string;
  label: string;
  url?: string;
};

export type footerColumn = {
  id: number;
  className?: string;
  header?: footerColumnHeader;
  items: footerItemContent[];
};

export type footerMenu = {
  menuClassName?: string;
  columns: footerColumn[];
} & footerSection;

export type NovartisFooter = {
  trademark: string;
  copyright: string;
  url: string;
  logo?: string;
  alt?: string;
} & footerSection;

export type footer = {
  brandFooter: footerMenu;
  novartisFooter: NovartisFooter;
} & footerSection;

type ContextParams = {
  phoneNumber: string;
  brandName: string;
  copayAmount: string;
  copayCap: number;
  website: string;
  digitalWallet: string;
  brandPhoneDisplay: string;
  currency: string;
  cardOffer: string;
};

export type Consent = {
  id: string;
  description: string;
  channel?: string[];
  impliedConsents?: string[];
};

export type Rule = {
  id: string;
  value: any;
};

export type ConfigLink = {
  text: string;
  link: string;
};

export type Welcome = {
  title: string;
  description: string;
  moreLink: ConfigLink;
};

export type StaticValues = {
  [name: string]: string;
};

export type InitialData = {
  id?: string;
  name?: string;
  title?: string;
  cardTitle?: string;
  img?: string;
  confirmationTitle?: string;
  columns?: AdherenceConfirmationColumn[];
  shortDescription?: string;
  longDescription?: string;
  confirmationDescription?: string;
  confirmationNextSteps?: string;
  confirmationFooter?: string;
  ineligibleTitle?: string;
  ineligibleDescription?: string;
  ineligibleNextSteps?: string;
  consents?: { explicit: string[]; service: string[] };
  disclaimer?: string;
  questions?: Rule[];
  static?: StaticValues;
  elegibility?: { patient?: Rule[]; caregiver?: Rule[]; questions?: Rule[] };
  legal?: string;
  fasttrack?: boolean;
};

export class ContextModel {
  flow: string = "";
  productId = "";
  languages: string[] = [];
  pagesWithAdditionalContext: string[] = [];
  socialNetworks: SocialNetwork[] = [];
  footerColumn: footerColumn = { id: 0, className: "", items: [] };
  footerMenu: footerMenu = {
    className: "",
    menuClassName: "",
    columns: [this.footerColumn],
  };
  footer: footer = {
    className: "",
    brandFooter: this.footerMenu,
    novartisFooter: { className: "", trademark: "", copyright: "", url: "" },
  };

  params: ContextParams = {
    brandName: "",
    phoneNumber: "",
    brandPhoneDisplay: "",
    cardOffer: "0",
    copayAmount: "",
    copayCap: 0,
    currency: "$",
    digitalWallet: "",
    website: "",
  };
  programs: Program[] = [];
  questions: { [key: string]: Question[] } = {};
  form: Form[] = [];
  pages: { [key: string]: any } = {};
  consents: { [key: string]: Consent } = {};
  menu: NavigationItemProps[] = [];

  constructor(rawJson?: ContextModel) {
    if (rawJson) {
      this.loadJSON(rawJson);
    }
  }
  get services(): Service[] {
    return this.programs.reduce((services: Service[], program: Program) => {
      return services.concat(program.services);
    }, []);
  }

  getService(name: string): Service | undefined {
    return this.services.find((service) => service.name === name);
  }

  getForm(formId: string) {
    return this.form.find((form) => form.id === formId);
  }

  filterQuestions(
    group: string,
    performer: string,
    discardedServices?: string[],
    previousAnswers?: { id: string; answer: Date | AnswerValueProp }[]
  ): Question[] {
    if (!this.questions[group]) {
      return [];
    }
    return this.questions[group].filter((question) => {
      if (question.performer && question.performer !== performer) {
        return false;
      }
      if (!question.conditions) {
        return true;
      }
      return question.checkConditions(
        discardedServices,
        previousAnswers,
        question.conditions
      );
    }, this);
  }

  getFilteredForms(
    performer: "patient" | "caregiver",
    discardedServices: string[],
    answers: InputQuestion[],
    consents: string[]
  ) {
    return this.form.filter((form) => {
      if (!form.service) {
        return true;
      }
      const service = this.services.find(
        (configService) => configService.name === form.service
      );
      if (service) {
        return (
          !discardedServices.includes(form.service) &&
          service.isEligible(performer, answers, consents)
        );
      }
      return false;
    });
  }

  getField(id: string): Field | undefined {
    let field: Field | undefined;

    this.form.some((configForm) => {
      field = configForm.fields.find(
        (serviceField) => serviceField.name === id
      );
      return field !== undefined;
    });
    return field;
  }

  initializeParams(rawJson: ContextModel) {
    this.productId = rawJson.productId;
    this.languages = rawJson.languages || [];
    this.menu = rawJson.menu || [];
    this.pagesWithAdditionalContext = rawJson.pagesWithAdditionalContext || [];
    this.socialNetworks = rawJson.socialNetworks || [];
    this.params = rawJson.params || {};
  }

  loadJSON(rawJson: ContextModel) {
    this.initializeParams(rawJson);
    if (rawJson.programs) {
      console.log("rawJson", rawJson);
      console.log("rawJson.programs", rawJson.programs);
      const programs = rawJson.programs.map(
        (program: Program) => new Program(program)
      );
      this.programs = programs;
    } else {
      this.programs = [];
    }
    this.questions = rawJson.questions ? loadQuestions(rawJson.questions) : {};
    this.pages = rawJson.pages || {};
    if (rawJson.form) {
      this.form = rawJson.form.map((form: Form) => new Form(form)) || {};
    }
    this.footer = rawJson.footer || {};
    this.consents = rawJson.consents || [];
  }
}

export interface AdherenceConfirmationColumn {
  title: string;
  header: string;
  items: string[];
  link?: string;
  imageCaption?: string;
}

// TODO: Remove unused attributes and remove the adherence columns as soon as the adherence MB version is not the Cosentyx version
export class Service {
  id: string = "";
  name: string = "";
  title: string = "";
  img?: string = "";
  columns?: AdherenceConfirmationColumn[];
  cardTitle?: string = "";
  confirmationTitle?: string = "";
  shortDescription: string = "";
  longDescription: string = "";
  confirmationDescription?: string = "";
  confirmationNextSteps?: string = "";
  confirmationFooter?: string = "";
  ineligibleTitle: string = "";
  ineligibleDescription: string = "";
  ineligibleNextSteps: string = "";
  consents: { explicit: string[]; service: string[] } = {
    explicit: [],
    service: [],
  };
  disclaimer?: string = "";
  questions: Rule[] = [];
  elegibility?: { patient?: Rule[]; caregiver?: Rule[]; questions?: Rule[] };
  static?: StaticValues;
  fasttrack?: boolean = false;
  legal?: string = "";

  constructor(initialData?: InitialData) {
    if (initialData) {
      this.id = initialData.id || this.id;
      this.title = initialData.title || this.title;
      this.cardTitle = initialData.cardTitle;
      this.img = initialData.img;
      this.columns = initialData.columns;
      this.initializeVerbose(initialData);
      this.consents = initialData.consents || this.consents;
      this.disclaimer = initialData.disclaimer || this.disclaimer;
      this.questions = initialData.questions || this.questions;
      this.name = initialData.name || this.name;
      this.static = initialData.static;
      this.fasttrack = initialData.fasttrack;
      this.elegibility = initialData.elegibility;
      this.legal = initialData.legal;
    }
  }

  private initializeVerbose(initialData: InitialData) {
    this.confirmationTitle =
      initialData.confirmationTitle || this.confirmationTitle;
    this.shortDescription =
      initialData.shortDescription || this.shortDescription;
    this.longDescription = initialData.longDescription || this.longDescription;
    this.confirmationDescription =
      initialData.confirmationDescription || this.confirmationDescription;
    this.confirmationNextSteps = initialData.confirmationNextSteps;
    this.confirmationFooter =
      initialData.confirmationFooter || this.confirmationFooter;
    this.ineligibleTitle = initialData.ineligibleTitle || this.ineligibleTitle;
    this.ineligibleDescription =
      initialData.ineligibleDescription || this.ineligibleDescription;
    this.ineligibleNextSteps =
      initialData.ineligibleNextSteps || this.ineligibleNextSteps;
  }

  private checkEligibility(
    subgroup: Rule[],
    answers: InputQuestion[]
  ): boolean {
    return subgroup.every((question) => {
      const answer = answers.find(
        (currentAnswer) => currentAnswer.id === question.id
      );
      if (answer) {
        return answer.isQualified(subgroup);
      }
      return true;
    });
  }

  explicitConsentIsMissing(consents: string[]) {
    return (
      this.consents.explicit &&
      this.consents.explicit.some((consent) => !consents.includes(consent))
    );
  }

  isEligible(
    performer: "caregiver" | "patient",
    answers: InputQuestion[],
    consents: string[],
    discardedServices?: string[]
  ): boolean {
    if (
      this.consents &&
      !discardedServices &&
      this.explicitConsentIsMissing(consents)
    ) {
      return false;
    }
    if (discardedServices && discardedServices.includes(this.name)) {
      return false;
    }
    if (this.elegibility) {
      const common =
        !this.elegibility.questions ||
        this.checkEligibility(this.elegibility.questions, answers);

      return (
        common &&
        (!this.elegibility[performer] ||
          this.checkEligibility(this.elegibility[performer]!, answers))
      );
    }
    return true;
  }
}

export class Program {
  id: string = "";
  title: string = "";
  services: Service[] = [];
  constructor(initialLoad?: {
    id?: string;
    title?: string;
    services?: Service[];
  }) {
    if (initialLoad) {
      if (initialLoad.id) {
        this.id = initialLoad.id;
      }
      if (initialLoad.title) {
        this.title = initialLoad.title;
      }
      if (initialLoad.services) {
        this.services = initialLoad.services.map(
          (service) => new Service(service)
        );
      }
    }
  }
}

export class Form {
  id: string = "";
  label: string = "";
  title: string = "";
  help?: string;
  description: string = "";
  service?: string;
  questions?: Rule[];
  fields: Field[] = [];
  mlrVersion?: string;
  constructor(data?: {
    id: string;
    title: string;
    label: string;
    help?: string;
    description: string;
    service?: string;
    questions?: Rule[];
    fields: Field[];
    mlrVersion?: string;
  }) {
    if (data) {
      this.id = data.id || "";
      this.title = data.title || "";
      this.label = data.label || "";
      this.help = data.help;
      this.description = data.description || "";
      this.service = data.service || "";
      this.questions = data.questions || [];
      this.fields = data.fields || [];
      this.mlrVersion = data.mlrVersion;
    }
  }
  isVisible(selectedServices: string[], answers: InputQuestion[]): boolean {
    let answer = true;
    if (this.service && this.service.length) {
      answer = selectedServices.includes(this.service);
    }
    if (answer && this.questions && this.questions.length) {
      answer = !this.questions.some((question) => {
        const existingAnswer = answers.find(
          (currentAnswer) => currentAnswer.id === question.id
        );
        if (existingAnswer) {
          return !existingAnswer.isQualified(question.value);
        }
        return true;
      });
    }
    return answer;
  }
}
