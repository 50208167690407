import React, { useContext, useState } from 'react';
import { window } from 'browser-monads';
import {
	EnrollmentContextService,
	EnrollmentContextProvider,
	UserInputType,
} from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/enrollment/enrollment-context';
import context, { ContextService } from '@nvs/gatsby-theme-leap-core/src/components/hoc/context/product/context';
import { PerformerType } from '@nvs/gatsby-theme-leap-core/src/components/hoc/constants/performer.constants';
import { routes } from '@nvs/gatsby-theme-leap-core/src/components/domains/flow/enrollment-flow';
import { homePath, basePath } from '@nvs/gatsby-theme-leap-core/src/components/domains/flow/flow';
import { ConsentIDs } from '@nvs/gatsby-theme-leap-core/src/components/hoc/constants/service.constants';

export interface MockProviderProps {
	children: React.ReactNode;
	value: EnrollmentContextService;
}
export function MockProvider({ children, value }: MockProviderProps) {
	const productCtxt = useContext(context);
	const [enrollmentCtxt] = useState(getContext(value, productCtxt));

	return (
		<EnrollmentContextProvider value={enrollmentCtxt}>
			{children}
		</EnrollmentContextProvider>
	);
}

export default MockProvider;

export enum paramKeys {
	PERFORMER = 'performer',
	BIRTHDATE = 'birthDate',
	INSURANCE = 'insurance_type',
	SERVICES = 'services',
	CONSENTS = 'consents',
	PROFILE = 'profile',
}

export type Query = {
	services: string[];
	consents: string[];
	performer?: PerformerType;
	birthDate?: boolean;
	insurance?: string;
	profile?: boolean;
};

function isAfterWizard() {
	return (
		window.location.pathname !== homePath &&
		window.location.pathname !== `${homePath}${basePath}/` &&
		!window.location.pathname.includes(routes.wizard)
	);
}

function getQuery(
	ctxt: EnrollmentContextService,
	productCtxt: ContextService
): Query {
	const params: Map<string, string> | undefined = new Map<string, string>(
		window.location.search
			?.slice(1)
			.split('&')
			.map((item: string) => {
				const keyValuePair = item.split('=');
				return [keyValuePair[0], keyValuePair[1]];
			})
	);
	let defaultQuery: Query = {
		services: [],
		consents: [],
	};
	if (isAfterWizard()) {
		defaultQuery = {
			performer: PerformerType.PATIENT,
			birthDate: true,
			insurance: 'private',
			services: productCtxt.json.services.map((service) => service.name),
			consents: Object.entries(productCtxt.json.consents)
				.filter(([k]) => k !== 'transactional' && k !== 'marketing')
				.map(([k]) => k),
			profile: true,
		};
	}

	return {
		performer:
			params?.get(paramKeys.PERFORMER) ??
			ctxt.getQuestion(paramKeys.PERFORMER)?.answer ??
			defaultQuery.performer,
		birthDate: params?.get(paramKeys.BIRTHDATE)
			? params?.get(paramKeys.BIRTHDATE) === 'true'
			: ctxt.getQuestion(paramKeys.BIRTHDATE)?.answer ?? defaultQuery.birthDate,
		insurance:
			params?.get(paramKeys.INSURANCE) ??
			ctxt.getQuestion(paramKeys.INSURANCE)?.answer ??
			defaultQuery.insurance,
		services:
			params?.get(paramKeys.SERVICES)?.split(',') ??
			(ctxt.getQuestion(paramKeys.SERVICES)?.answer
				? ctxt.getQuestion(paramKeys.SERVICES)?.answer.toString().split(',')
				: defaultQuery.services),
		consents:
			params?.get(paramKeys.CONSENTS)?.split(',') ??
			(ctxt.input.consents.length
				? ctxt.input.consents
				: defaultQuery.consents),
		profile: params?.get(paramKeys.PROFILE)
			? params?.get(paramKeys.PROFILE) === 'true'
			: defaultQuery.profile,
	};
}

function getContext(
	ctxt: EnrollmentContextService,
	productCtxt: ContextService
) {
	const query = getQuery(ctxt, productCtxt);
	console.log('Query', query);

	ctxt.input.performer =
		(query.performer as PerformerType) || PerformerType.PATIENT;

	query.performer &&
		ctxt.answerQuestion({
			id: paramKeys.PERFORMER,
			title: '',
			answer: query.performer,
			type: 'choice',
			params: [],
		});

	typeof query.birthDate == 'boolean' &&
		ctxt.answerQuestion({
			id: paramKeys.BIRTHDATE,
			title: '',
			answer: query.birthDate,
			type: 'boolean',
			params: [],
		});

	query.insurance &&
		ctxt.answerQuestion({
			id: paramKeys.INSURANCE,
			title: '',
			answer: query.insurance,
			type: 'choice',
			params: [],
		});

	query.services.length &&
		ctxt.answerQuestion({
			id: paramKeys.SERVICES,
			title: '',
			answer: query.services.join(','),
			type: 'choice',
			params: [],
		});

	if (query.services?.length) {
		query.services.forEach((service) => ctxt.addService(service));
		productCtxt.json.services
			.filter((service) => !query.services.includes(service.name))
			.forEach((service) => ctxt.addDiscardedService(service.name));
	}
	query.consents.forEach((consent) => ctxt.addConsent(consent));

	if (isAfterWizard()) {
		if (query.profile) {
			const inputMap: Map<string, string | boolean> = new Map(
				ctxt.input.form.map((field) => [field.id, field.value])
			);
			const defaultMap: Map<string, string | boolean> = new Map(
				[
					{ id: 'firstname', value: 'Test' },
					{ id: 'lastname', value: 'Test' },
					{ id: 'gender', value: 'female' },
					// TODO For kids, calculate the birth date based on the system date
					{ id: 'dob', value: query.birthDate ? '01/01/2001' : '01/01/2020' },
					{ id: 'medication', value: 'No' },
					{ id: 'email', value: '' },
					{ id: 'mail', value: 'Test' },
					{ id: 'apt', value: '1' },
					{ id: 'city', value: 'Test' },
					{ id: 'state', value: 'NJ' },
					{ id: 'zip', value: '10001' },
					{ id: 'firstnamecaregiver', value: 'Test' },
					{ id: 'lastnamecaregiver', value: 'Test' },
					{ id: 'gendercaregiver', value: 'female' },
					{ id: 'mailcaregiver', value: 'Test' },
					{ id: 'aptcaregiver', value: 'NJ' },
					{ id: 'citycaregiver', value: 'Test' },
					{ id: 'statecaregiver', value: 'NJ' },
					{ id: 'zipcaregiver', value: '10001' },
					{ id: 'phone', value: '5555555555' },
					{ id: 'mobile', value: '5555555555' },
					{ id: 'phoneusage', value: 'home' },
					{ id: 'consenttcpa', value: query.consents.includes('tcpa') },
					{ id: 'consenthipaa', value: query.consents.includes('hipaa') },
					{ id: 'bestCommunication', value: true },
					{ id: 'bestTimeToConnect', value: 'MORN' },
				].map((field) => [field.id, field.value])
			);
			ctxt.input.form = Array.from(
				new Map([...defaultMap, ...inputMap]),
				(item) => {
					return { id: item[0], value: item[1] };
				}
			);
		} else {
			ctxt.resetInput(UserInputType.FORMS);
			if (productCtxt.json.consents[ConsentIDs.hipaa]) {
				ctxt.input.form = [{ id: 'consenthipaa', value: false }];
			}
		}
	}

	return ctxt;
}